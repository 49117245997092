$background_1: #10163a;
$background_2: #262c49;
$background_3: #0090ff;

$fontXxxl: 2rem;
$fontXxl: 1.5rem;
$fontXl: 1.25rem;
$fontLg: 1rem;
$fontMd: 0.875rem;
$fontSm: 0.75rem;

.breadcrumb {
    padding: 1rem;
}
.main {
    padding: 1rem;
}
.layout {
    min-height: 100vh;
}
.sider {
    overflow: auto;
    min-height: 100vh;
    left: 0;
}
.content {
    // margin-left: 240px;
}
.footer {
    text-align: center;
    color: #999;
    font-size: 0.875em;
    padding: 0 1rem 1rem 1rem;
}
.logo {
    float: left;
    width: 220px;
    height: 64px;
    line-height: 64px;
    margin: 0 0 0 20px;
    img {
        max-width: 200px;
        // height: 100%;
    }
}

.pageBrief {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: row;
    .pageTitle {
        flex: 1;
        font-size: 1.25rem;
        font-weight: bold;
        small {
            font-size: 0.875rem;
            font-weight: normal;
            margin: 0 0 0 1rem;
            color: #666;
        }
    }
    .pageActions {
        flex: 1;
        text-align: right;
    }
}
.formContainer {
    background: #fff;
    padding: 1rem;
    .formTitle {
        font-size: 1rem;
        font-weight: bold;
        padding: 0 0 1rem 0;
    }
}
.tableContainer {
    background: #fff;
    padding: 1rem;
    .tableFilters {
        margin-bottom: 0.5rem;
    }
}
.uploadThumb {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
        height: 100px;
    }
}
.uploadBanner {
    .ant-upload.ant-upload-select-picture-card {
        width: 244px !important;
    }
}
.uploadBannerThumb {
    width: 240px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
        height: 100px;
    }
}
.itinerarySelects {
    margin-bottom: 1rem;
    .selector {
        margin-bottom: 1rem;
    }
    .itinerary {
        padding: 0.5rem;
        background: #fafafa;
        border: 1px solid #cdcdcd;
        border-radius: 0.5rem;
        .title {
            font-weight: bold;
            font-size: 0.875rem;
            padding: 0.5rem;
        }
        .content {
            overflow-y: scroll;
            height: auto;
            max-height: 10rem;
            font-size: 0.75rem;
            line-height: 0.875rem;
            padding: 0.5rem;
        }
        .desc {
            font-size: 0.75rem;
            line-height: 0.875rem;
            padding: 0.5rem;
        }
    }
}
.operateCalendar {
    .operateProduct {
        text-align: left !important;
        background: #efefef;
        padding: 0.25rem;
        margin-top: 0.25rem;
    }
}
.orderProductCalendar {
    padding: 0.5rem;
    // background: #fafafa;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.orderAddon,
.orderBand {
    margin-bottom: 0.5rem;
}
.orderAddon:last-child,
.orderBand:last-child {
    margin-bottom: 0;
}
.orderTotal {
    .orderCurrency {
        color: #f90;
    }
    .totalAmount {
        color: #f90;
        margin-left: 0.5rem;
        font-size: $fontXxl !important;
        font-weight: bold;
    }
}
.productPriceCalendar {
}
.productDetail {
    background: #fff;
    padding: 1rem;
    .productName {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .productSubName {
        font-size: 1.25rem;
        color: #666;
    }
    .productLangCodes {
        margin-top: 0.5rem;
    }
    .productTags {
        margin-top: 0.5rem;
    }
    .productPhoto {
        width: 100%;
        overflow: hidden;
        height: 22rem;
        text-align: center;
        img {
            height: 100%;
            margin: 0 auto;
        }
    }
    .productOptions {
        position: absolute;
        padding-top: 0.75rem;
        .productOption {
            display: inline-block;
        }
        .productAgents {
            width: 10rem;
            margin-left: 1rem;
            display: inline-block;
        }
    }
    .productParam {
        .productParamDivider::after {
            content: " / ";
        }
        .productParamDivider:last-child::after {
            content: "";
        }
    }
    .productField {
        .fieldTitle {
            font-size: 1.125rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        .productItinerary {
            margin-bottom: 1rem;
            .itineraryName {
                font-weight: bold;
                font-size: 1rem;
            }
            .itineraryIntro {
                margin-top: 0.5rem;
            }
            .itineraryDesc {
                margin-top: 0.5rem;
            }
        }
        .productItinerary:last-child {
            margin-bottom: 0;
        }
    }
}
.productInventoryCalendar {
    margin-top: -1rem;
    .ant-picker-calendar-header {
        justify-content: flex-start;
    }
}
.productComboPlan {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid #EEE;
    margin-bottom: 0.5rem;
    .planTitle {
        font-weight: bold;
        font-size: $fontMd;
    }
    .ant-checkbox-disabled + span {
        color: #666 !important;
    }
    .comboProducts {
        padding-left: 1.5rem;
        padding-bottom: 0.5rem;
        .comboProduct {
            margin-top: 0.25rem;
        }
    }
}

.homeStatus {
    .ant-card-head-title {
        text-align: center;
    }
    .homeStatusContent {
        text-align: center;
        font-size: $fontXxxl;
        font-weight: bold;
        height: 3.5rem;
        overflow: hidden;
    }
}

// LOGIN
.loginMain {
    height: 100vh;
    background-image: url(../img/login.jpg);
    background-size: cover;
    .loginLogo {
        text-align: center;
        margin: 10vh auto 0 auto;
        max-width: 25rem;
        overflow: hidden;
    }
    .loginContainer {
        background: #193c91;
        border: 1px solid #0ba5ff;
        color: #fff;
        margin: 1rem auto;
        max-width: 25rem;
        overflow: hidden;
        .loginLanguageSwith {
            text-align: right;
            padding: 1rem 1rem 0 0;
            cursor: pointer;
        }
        .loginTitle {
            font-size: 1.25em;
            font-weight: bold;
            text-align: center;
            color: #fff;
            padding: 0.5rem 0 1rem 0;
        }
        .loginForm {
            padding: 0 0 1.5rem 0;
            .ant-input-affix-wrapper {
                background-color: transparent !important;
                border: 1px solid #0ba5ff;
                .ant-input-prefix {
                    color: #0ba5ff !important;
                    padding-right: 0.5rem;
                }
            }
            .ant-input,
            input {
                background: transparent !important;
                color: #fff;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s;
            }
        }
        .loginButton {
            margin: 0 auto;
            max-width: 12rem;
        }
    }
    .footer {
        background: none;
    }
}

/* HEADER */
.headerActions {
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    .headerMerchant {
        flex: 1;
        .merchantName {
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    .headerUserActions {
        flex: 1;
        text-align: right;
        .languageSwitch {
            color: #fff;
            margin-right: 2rem;
            cursor: pointer;
            padding: 0.5rem;
        }
        .userInfo {
            .userName {
                padding: 0 0.5rem 0 0;
                color: #fff;
            }
        }
    }
}

/* FIX */
.ant-layout-sider {
    background-color: $background_1;
}
.ant-layout-sider-trigger {
    background-color: $background_2;
}
.ant-menu.ant-menu-dark {
    background-color: $background_1;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: $background_2;
}
.ant-layout-header {
    background-color: $background_1;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $background_3;
}
.ant-layout-header {
    padding: 0 !important;
}
.ant-breadcrumb {
    background: #fff;
}
.ql-editor {
    min-height: 10rem;
}
.ql-container {
    font-family: inherit !important;
}
.ant-picker-calendar-date-content {
    height: auto !important;
}
.ant-picker-calendar-mode-switch {
    display: none;
}
.sider {
    .navBadge {
        position: absolute;
        top: 0.75rem;
        right: 1rem;
    }
    .ant-badge-count {
        box-shadow: none !important;
        margin-left: 0.5rem;
    }
}
.rpCalendar {
    padding: 5px;
    border-top: 2px solid #efefef;
    background: #fafafa;
    margin: 5px;
    min-height: 5rem;
    .date {
        margin-bottom: 5px;
        font-weight: bold;
    }
    .actions {
        // min-height: 2rem;
    }
    .prices {
        // min-height: 2rem;
    }
    .ant-input-number {
        width: auto !important;
    }
    .discountPrice {
        color: #d44e4b;
    }
    .normalPrice {
        color: #2d46d0;
    }
    .copyButton {
        color: $background_3;
        background: #fff;
        border: 1px dashed $background_3 !important;
        border: none;
    }
}
.rpCalendar-chosen {
    background: #c3ddff;
}
.ant-picker-cell {
    vertical-align: top !important;
    // height: 5rem;
}

// COMMON
.spinLoading {
    text-align: center;
    padding: 1rem;
}
.spanDivider::after {
    content: " / ";
}
.spanDivider:last-child::after {
    content: "";
}
.al {
    text-align: left;
}
.ac {
    text-align: center;
}
.ar {
    text-align: right;
}
.fb {
    font-weight: bold;
}
.m0 {
    margin: 0;
}
.mb0 {
    margin-bottom: 0;
}
.ml1 {
    margin-left: 1rem;
}
.mr1 {
    margin-right: 1rem;
}
.mx1 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mt1 {
    margin-top: 1rem;
}
.mb1 {
    margin-bottom: 1rem;
}
.my1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.mp5 {
    margin: 0.5rem;
}
.mtp5 {
    margin-top: 0.5rem;
}
.mbp5 {
    margin-bottom: 0.5rem;
}
.myp5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.mlp5 {
    margin-left: 0.5rem;
}
.mrp5 {
    margin-right: 0.5rem;
}
.mxp5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.m5p {
    margin: 5px;
}
.mt5p {
    margin-top: 5px;
}
.mb5p {
    margin-bottom: 5px;
}
.my5p {
    margin-top: 5px;
    margin-bottom: 5px;
}
.ml5p {
    margin-left: 5px;
}
.mr5p {
    margin-right: 5px;
}
.mx5p {
    margin-left: 5px;
    margin-right: 5px;
}
.d25 {
    width: 25%;
}
.d50 {
    width: 50%;
}
.d90 {
    width: 90%;
}
.d95 {
    width: 95%;
}
.d100 {
    width: 100%;
}
.ilb {
    display: inline-block;
}
.ib {
    display: block !important;
}
.lhi {
    line-height: inherit;
}
.clear {
    clear: both;
}
.cp {
    cursor: pointer;
}
.c1 {
    color: #54b4ef;
}
.c2 {
    color: #e07472;
}
.c3 {
    color: #5fcda2;
}
.c4 {
    color: #9789f7;
}
.c5 {
    color: #ee883b;
}
.c11 {
    color: #f90;
}
.c12 {
    color: #999;
}
.c13 {
    color: #f00;
}
.c14 {
    color: rgb(12, 122, 80);
}
.c15 {
    color: rgb(173, 68, 142);
}
.c16 {
    color: rgb(91, 68, 173);
}
.c17 {
    color: #f30;
}
.fontSm {
    font-size: $fontSm;
}
.fontMd {
    font-size: $fontMd;
}
.fontLg {
    font-size: $fontLg;
}
.fontXl {
    font-size: $fontXl;
}
.fontXxl {
    font-size: $fontXxl;
}
.editor {
    height: 20rem !important;
}
.flag {
    position: relative;
    display: inline-block;
    width: 1.2em;
    height: 0.9em;
    line-height: 1em;
    margin-right: 0.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}
.flag_cn {
    background-image: url(../img/flags/cn.svg);
}
.flag_us {
    background-image: url(../img/flags/us.svg);
}
.flag_hk {
    background-image: url(../img/flags/hk.svg);
}
